.accordion-button {
    display: block;
    cursor: pointer;
    &.disabled {
        cursor: default;
        pointer-events: none;
        .text-dark {
            color: rgba(var(--bs-dark-rgb), 0.6) !important;
        }
    }
}

.sidebar .nav .nav-item {
    display: block;
    cursor: pointer;
}

.order-input {
    input {
        max-width: 100%;
    }
}

.list-wrapper {
    .song {
        &.selected {
            .mdi-check-circle-outline::before {
                content: "\F5E0" !important;
            }
        }
        &:not(.selected){
            .mdi-check-circle::before {
                content: "\F5E1" !important;
            }
        }
    }
}

.form-group.required {
    label:after {
        content:"*";
    }
}

a#activeEnsembleDropdown {
    color: $dark;
    text-decoration: none;
}

.active-ensemble-dropdown {
    .dropdown-divider {
        margin: 0;
    }
}
.unread-count-container {
    position: relative;
    .unread-count {
        color: white;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        background: #ff0854;;
        text-align: center;
        line-height: 18px;
        font-size: 15px;
        font-weight: bold;
        position: absolute;
        right: -10px;
        bottom: 0;
    }
}

.new-message {
    font-weight: bold;
}


.youtube-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */

    .responsive-iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border: none;
    }
}

.table td.table-text {
    white-space: normal;
}

.event-list-item {
    .event-date {
        i {
            margin-right: 5px;
        }
    }
    .event-name {
        margin-bottom: 0;
        a {
            color: $body-color;
            text-decoration: none;
        }
    }
}

#set-thresholds-form {
    i.remove {
        font-size: 20px;
        color: red;
        cursor: pointer;
    }
}

#event-check-list {
    .card-title {
        padding-bottom: 24px;
        border-bottom: 1px solid $border-color;
    }
    .user_row {
        padding-top: 8px;
        padding-bottom: 8px;
        .event-check-list-username {
            border-bottom: 1px solid $border-color;
        }

    }
}

.event-check-list-user-declaration,
.event-view-declaration-label {
    text-transform: lowercase;
    color: darkgrey;
    &:not(:empty) {
        &::before {
          content: " - ";
        }
    }
}

.event-check-list-declaration-comment {
    font-style: italic;
    &:not(:empty) {
        &::before, &::after {
          content: '"';
        }
    }
}

.two-tabs-switch {
    .tickets-tab-switch {
      .nav-item {
          width: 50%;
           @include media-breakpoint-down(sm) {
              width: 100%;
          }
      }
    }
}

.nav-scroller-narrow {
    .tickets-tab-switch {
        padding: 0;
        .nav-item .nav-link {
            padding-left: 10px;
            padding-right: 10px;
            .badge {
                z-index: 2;
            }
        }
    }
}

#song-voices {
    .song-voice-item {

        border-bottom: 1px solid #e4e9f0;

        &:first-of-type {
            .list-move-up {
                visibility: hidden;
                pointer-events: none;
            }
        }
        &:last-of-type {
            .list-move-down {
                visibility: hidden;
                pointer-events: none;
            }
        }

        .list-move-up, .list-move-down {
            padding-right: 12px;
            color: rgb(160, 160, 160);
            cursor: pointer;
            i {
                font-size: 35px;
                line-height: 25px;
            }
        }

        .repeater-delete {
            font-size: 22px;
            line-height: 36px;
            color: red;
            cursor: pointer;
        }
    }
}

#event-songs-list {

    .selectedSongs {
        &:not(.d-none){
            &:first-of-type {
                .list-move-up {
                    visibility: hidden;
                    pointer-events: none;
                }
            }
            &.last-of-type {
                .list-move-down {
                    visibility: hidden;
                    pointer-events: none;
                }
            }
        }

        .list-move-up, .list-move-down {
            padding-right: 12px;
            color: rgb(160, 160, 160);
            cursor: pointer;
            i {
                font-size: 35px;
                line-height: 35px;
            }
        }
        .song-order {
            display: block;
            padding-right: 12px;
            font-size: 20px;
            font-weight: bold;
            text-align: center;
        }
        .dragula-handle {
            color: rgb(160, 160, 160);
            i {
                font-size: 25px;
            }
        }
    }
}

.loading img {
    max-height: 50px;
}

body.mobile {
    .w-100-mobile {
        width: 100%;
    }
}

#overlay {
    display: none;
}
body.overlay {
   overflow: hidden;
   #overlay {
      display: block;
      background-color: rgba(0, 0, 0, 0.3);
      z-index: 10;
      position: fixed;
      height: 100%;
      width: 100%;
   }
}

@media (max-width: 600px) {
    .card-body.event-calendar-wrapper {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;

        .card-title {
            padding-left: 10px;
        }

        .fc {
            .fc-month-view {
                .fc-content-skeleton {
                    table {
                        tr td {
                            padding-top: 0;
                            padding-bottom: 0;
                        }
                    }
                }

                .fc-event {
                    padding-left: 2px;
                }

                .fc-time {
                    display: none;
                }

                .fc-event-container {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }
}


table.table-wrap {
    width: 100%;
    table-layout: fixed;
}

table.table-wrap td {
    white-space: wrap;
}

table.table-wrap td.table-row-title {
    width: 40%;
    word-wrap: break-word;
}

.strict-voice-search-inputs {
    display: inline-block;
    line-height: 14px;
    font-weight: 800;
}