.page-body-wrapper{
    min-height: 100vh
}

.sidebar .nav .nav-item:nth-child(2).active {
    background: $sidebar-light-menu-active-bg;
    & > .nav-link {
        i {
            color: $white;
        }
        .menu-title {
            color: $white;
            font-family: "Open Sans", sans-serif;
            font-weight: 600;
        }
    }
}

.jq-toast-wrap {
    width: 260px;
}

.jq-toast-single {
    font-size: 14px;
}

.tooltip-inner {
    a {
        color: white;
        text-decoration: none;
    }
}

select.form-control {
    color: #212529;
    &.select-placeholder {
        color: $input-placeholder-color;
    }
}

.datepicker-dropdown {
    z-index: 1031 !important;
}

@media (min-width: 992px) {
    .navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu {
    left: -92px;
    &:before {
        left: 126px;
    }
  }
}
